::-webkit-scrollbar {
  width: 8px; /*对垂直流动条有效*/
  height: 8px; /*对水平流动条有效*/
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #dcdfe6;
}

.td-long-deal {
  //max-width: 110px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.search-box {
  padding: 8px;
}

.search-box input {
  width: 188px;
  margin-bottom: 8px;
  display: block;
}

.search-box button {
  width: 90px;
}

.search-button {
  margin-right: 8px;
}

::ng-deep .text-center {
  text-align: left !important;
}

.shadow {
  box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.1);
}

.filter-bg {
  width: 100%;
  height: auto;
  background: rgba(239, 239, 239, 0.5);
  border-radius: 2px;
  padding: 10px 0 0 10px;
  margin-bottom: 10px;

  .ant-form-item-label {
    text-align: left !important;
    min-width: 90px;
  }
}

.filter-bg-form-item-bottom {
  // 表单label左对齐
  .ant-form-item {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
  }
}

//列表搜索部分
.list-search-part {
  //padding-bottom: 10px;
  //position: relative;
  //left: -16px;

  .ant-select-selection-item {
    max-width: 10vw;
  }

  .ant-form-item {
    display: flex;
    flex-wrap: nowrap;
    //overflow: auto;
    //overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
  }

  .ant-form-item-label {
    text-align: left !important;
    min-width: 90px;
  }

  .form-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;

    .form-col-4 {
      width: calc(25% - 20px);
    }

    .form-col-3 {
      width: calc(33% - 25px);
    }

    .form-col-2 {
      width: calc(50% - 20px);
    }
  }
}

.inventory-left {
  width: calc(100% - 200px);
}

.part-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-between-auto {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.link-operate {
  color: #0091ff;
  cursor: pointer;
}

//详情基本信息样式
.part {
  padding: 15px 20px 20px 20px;

  .basic-info {
    .type {
      display: flex;
      font-size: 16px;
      height: 20px;
      font-weight: 600;
      color: #333;
      line-height: 20px;
      align-items: center;

      .type-symbol {
        width: 4px;
        height: 16px;
        background: #0091ff;
        border-radius: 2px;
        margin-right: 7px;
      }
    }
  }
}

.special-table {
  font-size: 14px;
  width: 100%;
  border: 1px solid #efefef;
  padding: 0;
  work-break: break-all;
  word-wrap: break-word;
  margin: 10px 0 15px 0;

  .special-tr {
    display: flex;
    line-height: 36px;
  }

  .special-td {
    border-bottom: 1px solid #efefef;
    border-right: 1px solid #efefef;
    padding: 0 10px;
  }

  .td-label {
    width: 150px;
    background: rgba(239, 239, 239, 0.5);
    font-weight: 500;
    color: #42637b;
  }

  .td-label-1 {
    width: 180px;
    background: rgba(239, 239, 239, 0.5);
    font-weight: 500;
    color: #42637b;
  }

  .td-value-1 {
    width: calc(100% - 150px);
    line-height: 22px;
    padding: 3px 10px;
    font-weight: 500;
    color: #686868;
    white-space: normal;
    word-break: break-all;
  }

  .td-value-3 {
    width: calc(33.4% - 150px);
    line-height: 22px;
    padding: 3px 10px;
    font-weight: 500;
    color: #686868;
    white-space: normal;
    word-break: break-all;
  }

  .td-value {
    width: calc(50% - 150px);
    line-height: 22px;
    padding: 3px 10px;
    font-weight: 500;
    color: #686868;
    white-space: normal;
    word-break: break-all;
  }

  .row-list {
    padding: 7px 0;

    .row {
      height: 20px;
      line-height: 20px;
      color: #0091ff;
      cursor: pointer;
    }
  }
}

.special-table > .special-tr:last-child > .special-td {
  border-bottom: none;
}

.range-filter {
  display: flex;
  flex-wrap: nowrap;

  .divide-symbol {
    margin: 0 10px;
  }
}

.add-box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  .add-each {
    width: auto;
    font-size: 12px;
    padding: 0 10px;
    background: gray;
    margin: 0 10px 10px 0;
    height: 25px;
    line-height: 25px;
    border-radius: 4px;
    color: #fff;
    position: relative;

    .close-icon {
      display: none;
    }
  }

  .add-each:hover {
    .close-icon {
      display: block !important;
      background: rgba(128, 128, 128, 0.7);
      color: #ffffff;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      text-align: center;
      line-height: 10px;
      position: absolute;
      right: -5px;
      top: -7px;
      cursor: pointer;
    }
  }
}

.input-selected-box {
  display: flex;
  flex-wrap: wrap;
  background: rgba(0, 0, 0, 0.1);
  padding: 10px 10px;

  .input-selected-each {
    width: auto;
    font-size: 12px;
    padding: 0 10px;
    background: gray;
    margin: 0 10px 10px 0;
    height: 25px;
    line-height: 25px;
    border-radius: 4px;
    color: #fff;
    position: relative;
  }
}

.require-icon {
  color: red;
}

//查看审核弹窗样式
.audit-view-frame {
  //详情基本信息样式
  .audit-basic-info {
    .audit-type {
      display: flex;
      font-size: 14px;
      height: 20px;
      font-weight: 600;
      color: #333;
      line-height: 20px;
      align-items: center;

      .audit-type-symbol {
        //width: 4px;
        //height: 16px;
        //background: #0091ff;
        //border-radius: 2px;
        //margin-right: 7px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #0091ff;
        margin: 5px 6px 5px 0;
      }
    }
  }
}

.audit-flow {
  min-height: 60vh;
  padding-left: 40px;

  .audit-flow-each {
    color: #999;
    font-size: 14px;
    line-height: 30px;

    .flow-top {
      display: flex;

      .flow-log {
        width: 40px;
        height: 40px;
        line-height: 40px;
        background: #58a5ec;
        border-radius: 50%;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        margin-right: 10px;
      }

      .flow-header {
        font-size: 14px;
        font-weight: 500;
        color: #333;
        line-height: 40px;
      }
    }

    .flow-content {
      margin-left: 60px;
      border-left: 1px solid #d1d1d1;
      min-height: 80px;
      padding-left: 20px;
      height: auto;

      .flow-node {
        font-weight: 400;
      }

      .flow-remark {
        width: auto;
        min-height: 32px;
        background: #efefef;
        font-size: 12px;
        font-weight: 400;
        color: #999;
        padding: 5px 10px;
        height: auto;
        line-height: 20px;
      }

      .flow-date {
        font-size: 12px;
        font-weight: 400;
        color: #999;
      }
    }
  }
}

//详情页头部部分样式
.info-top-part {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 4px;
  min-height: 100px;
  padding: 15px 20px;

  .info-top-part-head {
    .time-tip {
      margin-left: 7px;
      font-size: 12px;
      font-weight: 400;
      color: #999;
    }
  }

  .general-info {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    height: 16px;
    line-height: 16px;

    .divide {
      margin: 0 15px;
      position: relative;
      top: 1px;
    }
  }

  .label {
    font-size: 14px;
    font-weight: 400;
    color: #999;
  }

  .value {
    font-size: 12px;
    font-weight: 400;
    color: #333;
  }

  .value-remark {
    max-width: 800px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

// 图标颜色更换
.img-box-show {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px;
  overflow: hidden;
}

.img-box-hidden {
  display: inline-block;
  position: relative;
  left: 28px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.img-show {
  position: absolute;
  left: -28px;
  filter: drop-shadow(28px 0 #0091ff);
  cursor: pointer;
}

.img-hidden {
  position: absolute;
  top: 0;
  left: -28px;
  cursor: pointer;
}

.iconfont-left-right {
  margin: 0 5px;
}

.iconfont {
  cursor: pointer;
}

.table-pagination-box {
  text-align: right;
  line-height: 50px;
  //margin: 10px 0
}

//显示内容区域初始设置
.page-content-frame {
  //min-width: 1230px;
  width: 100%;
  //min-height: calc(100vh - 120px);
  //border: 1px solid red;
}

.inOrOut-symbol {
  background: #f3faff;
  border-radius: 4px 4px 4px 0;
  border: 1px solid #0091ff;
  font-size: 10px;
  font-weight: 400;
  color: #0091ff;
  padding: 0 6px;
  height: 19px;
  line-height: 18px;
}

//最大标题
.big-title {
  height: 28px;
  font-size: 20px;
  font-weight: 600;
  color: #333;
  line-height: 28px;
  margin-right: 15px;
}

// 常用标题
.normal-title {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.icon-button-group {
  color: #0091ff;
  font-size: 12px;
  margin-right: 5px;
}

//项目详情
.project-detail {
  width: 100%;
  min-width: 1230px;

  .status-select {
    display: inline-block;
    width: 110px;
    height: 32px;
    line-height: 32px;
    border: 1px solid #1890ff;
    padding: 0 15px;
    border-radius: 2px;
    margin-right: 8px;
    color: #ffffff;
    background: #1890ff;
  }

  .status-select-2 {
    display: inline-block;
    width: 110px;
    height: 32px;
    line-height: 32px;
    border: 1px solid #1890ff;
    padding: 0 15px;
    border-radius: 2px;
    margin-right: 8px;
    color: #1890ff;
  }

  .belong {
    margin-right: 8px;
    background: #678eee;
    border-radius: 2px;
    height: 24px;
    line-height: 24px;
    padding: 0 6px;
    color: #fff;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-right: 15px;
  }

  .general-info {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 8px 0;

    .divide {
      margin: 0 15px;
      position: relative;
      top: 1px;
    }
  }

  .part {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 4px;
    min-height: 100px;
    padding: 15px 20px 20px 20px;

    .stat {
      .stat-title {
        font-size: 16px;
        font-weight: 500;
        color: #333;
        margin: 0 0 15px 0;
      }

      .type {
        font-size: 14px;
        font-weight: 400;
        color: #686868;
        margin: 7px 0;
        display: flex;
        align-items: center;

        .dot {
          display: inline-block;
          width: 6px;
          height: 6px;
          background: #0091ff;
          border-radius: 50%;
          margin-right: 5px;
        }
      }

      .num {
        font-size: 16px;
        font-weight: 600;
        color: #333;
        padding-left: 10px;
      }
    }

    .stat-1 {
      min-width: calc(100% - 520px);
      width: 58.9%;
      border-right: 1px dashed #d1d1d1;
      padding-left: 5px;

      .stat-1-content {
        height: 132px;
        background: rgba(228, 243, 255, 0.5);
        border-radius: 10px;
        margin-right: 20px;
        display: flex;
        padding: 5px 10px;
        justify-content: space-between;

        .each {
          width: calc(25% - 25px);
          line-height: 30px;
          font-size: 16px;
          font-weight: 600;
          color: #333;
          display: flex;
          flex-direction: column;
          align-items: center;

          .title {
            font-size: 14px;
            font-weight: 500;
            color: #686868;
            line-height: 18px;
            margin: 5px 0 5px 0;
          }
        }

        .each-2 {
          color: #fff;
          background: #0091ff;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          border-radius: 4px;
          height: 140px;
          position: relative;
          top: -10px;

          .title {
            color: #fff;
            margin-top: 15px;
          }
        }

        .each:hover {
          color: #fff;
          background: #0091ff;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          border-radius: 4px;
          height: 140px;
          position: relative;
          top: -10px;

          .title {
            color: #fff;
            margin-top: 15px;
          }
        }

        .each-1 {
          width: 100px;
          text-align: right;
          padding-right: 10px;
          font-size: 12px;
          font-weight: 400;
          color: #333;
          line-height: 30px;
        }
      }
    }

    .stat-2 {
      min-width: 220px;
      padding: 0 20px;
      width: 18.3%;
      border-right: 1px dashed #d1d1d1;
    }

    .stat-3 {
      min-width: 300px;
      width: 23.5%;
      padding: 0 20px;
    }
  }

  .label {
    font-size: 14px;
    font-weight: 400;
    color: #999;
  }

  .value {
    font-size: 14px;
    font-weight: 400;
    color: #333;
  }

  .value-remark {
    max-width: 800px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

//订购合同详情
.order-detail {
  width: 100%;
  min-width: 1230px;

  .detail-top-left {
    display: flex;
    padding-right: 20px;
    width: calc(100% - 400px);

    .detail-top-left-right {
      display: flex;
      flex-direction: column;
      max-width: calc(100% - 300px);
    }
  }

  .detail-top-right-chart {
    width: 400px;
    border-left: 1px dashed #d8d8d8;
    padding-left: 20px;
    //border: 1px solid red;

    .chart-title {
      font-size: 14px;
      font-weight: 600;
      color: #333;
    }
  }

  .belong {
    margin-right: 8px;
    background: #678eee;
    border-radius: 2px;
    height: 24px;
    line-height: 24px;
    padding: 0 6px;
    color: #fff;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-right: 4px;
    //border: 1px solid red;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .contract-file-type {
    display: inline-block;
    padding: 0 10px;
    border-radius: 10px;
    font-size: 10px;
    font-weight: 500;
    border: 1px solid #007aff;
    color: #007aff;
    margin-right: 6px;
  }

  .general-info {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    //height: 16px;
    line-height: 16px;
    flex-wrap: wrap;

    .divide {
      margin: 0 15px;
      position: relative;
      top: 1px;
    }
  }

  .part {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 4px;
    min-height: 100px;
    padding: 15px 20px 30px 20px;
  }

  .label {
    font-size: 14px;
    font-weight: 400;
    color: #999;
  }

  .value {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    line-height: 30px;
  }

  .value-remark {
    max-width: 800px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

//下拉按钮组
.status-select {
  display: inline-block;
  width: 110px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #1890ff;
  padding: 0 15px;
  border-radius: 2px;
  margin-right: 8px;
  color: #fff;
  background: #1890ff;
}

// 详情页房源状态标识
.detail-symbol {
  //width: 70px;
  height: 24px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  padding: 0 17px;
  line-height: 24px;
  margin-right: 7px;
  color: #fff;
}

// st表格左对齐
.ant-table-tbody > tr > td {
  text-align: left !important;
}

.ant-table-container table > thead > tr th {
  text-align: left !important;
}

.form-part-bg {
  background: rgba(153, 153, 153, 0.24);
  padding: 20px 0 10px 0;
  border-radius: 4px;
}

.row-box {
  background-color: #ffffff;
  box-shadow: 7px 5px 6px 5px rgb(0, 0, 0, 0.04);
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;

  .row {
    padding: 8px 20px;
  }

  //.row:first-child {
  //  background-color: rgb(0, 0, 0, 0.01);
  //}

  .row:hover {
    background-color: rgb(0, 0, 0, 0.03);
  }
}

.batch-input-box {
  background: #ffffff;
  padding: 10px 10px 10px 10px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
