@font-face {
  font-family: "iconfont"; /* Project id 4021941 */
  src: url('iconfont.woff2?t=1683185895674') format('woff2'),
       url('iconfont.woff?t=1683185895674') format('woff'),
       url('iconfont.ttf?t=1683185895674') format('truetype'),
       url('iconfont.svg?t=1683185895674#iconfont') format('svg');
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family:'iconfont'!important;
  speak:none;
  font-style:normal;
  font-weight:normal;
  font-variant:normal;
  text-transform:none;
  /*font-size:1rem;*/
  /* Better Font Rendering =========== */
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}

.icon-cgx:before {
  content: "\e6cd";
}

.icon-wdxx:before {
  content: "\e6cc";
}

.icon-biyan:before {
  content: "\e6ca";
}

.icon-zhengyan:before {
  content: "\e6cb";
}

.icon-wodexiaoxi_fangyuanyichang:before {
  content: "\e6c5";
}

.icon-wodexiaoxi_xiangmu:before {
  content: "\e6c6";
}

.icon-wodexiaoxi_fangyuandongtai:before {
  content: "\e6c7";
}

.icon-wodexiaoxi_renwu:before {
  content: "\e6c8";
}

.icon-wodexiaoxi_shenqing:before {
  content: "\e6c9";
}

.icon-ic_sctx:before {
  content: "\e6c4";
}

.icon-ic_tc_wxz:before {
  content: "\e6be";
}

.icon-ic_tc_xz:before {
  content: "\e6bf";
}

.icon-xiazaifujian:before {
  content: "\e68f";
}

.icon-guanbi1:before {
  content: "\e6c0";
}

.icon-tianjia:before {
  content: "\e6c1";
}

.icon-mima:before {
  content: "\e6c2";
}

.icon-zhankaidaohang:before {
  content: "\e6c3";
}

.icon-bufenxuanze:before {
  content: "\e6b6";
}

.icon-weixuanze_danxuan:before {
  content: "\e6b9";
}

.icon-weixuanze:before {
  content: "\e6ba";
}

.icon-jinzhixuanze:before {
  content: "\e6bb";
}

.icon-xuanze_danxuan:before {
  content: "\e6bc";
}

.icon-xuanze:before {
  content: "\e6bd";
}

.icon-anniubianji:before {
  content: "\e69d";
}

.icon-anniuxiala:before {
  content: "\e69e";
}

.icon-anniugengxin:before {
  content: "\e6a7";
}

.icon-gengduo:before {
  content: "\e6a8";
}

.icon-chakanwendang:before {
  content: "\e6a9";
}

.icon-beizhu:before {
  content: "\e6aa";
}

.icon-paixu:before {
  content: "\e6ab";
}

.icon-quanping:before {
  content: "\e6ac";
}

.icon-guanbi:before {
  content: "\e6ad";
}

.icon-shanchu:before {
  content: "\e6ae";
}

.icon-shuaxin:before {
  content: "\e6af";
}

.icon-sousuo:before {
  content: "\e6b0";
}

.icon-shouqi:before {
  content: "\e6b1";
}

.icon-riqi:before {
  content: "\e6b2";
}

.icon-xiala:before {
  content: "\e6b3";
}

.icon-tianjiawendang:before {
  content: "\e6b4";
}

.icon-shezhichangyong:before {
  content: "\e6b5";
}

.icon-zhankai:before {
  content: "\e6b7";
}

.icon-xiazaishuju:before {
  content: "\e6b8";
}

.icon-shibai:before {
  content: "\e692";
}

.icon-tixing:before {
  content: "\e698";
}

.icon-chenggong:before {
  content: "\e69c";
}

.icon-dongjie2:before {
  content: "\e691";
}

.icon-yichang2:before {
  content: "\e693";
}

.icon-suoding2:before {
  content: "\e694";
}

.icon-a-yubeiancopy:before {
  content: "\e695";
}

.icon-weizhi:before {
  content: "\e689";
}

.icon-shangchuan:before {
  content: "\e68a";
}

.icon-news:before {
  content: "\e68b";
}

.icon-loupan:before {
  content: "\e68c";
}

.icon-wg_selected:before {
  content: "\e68d";
}

.icon-lb_unselected:before {
  content: "\e68e";
}

.icon-building:before {
  content: "\e690";
}

.icon-dongjie:before {
  content: "\e696";
}

.icon-suoding:before {
  content: "\e697";
}

.icon-grzl:before {
  content: "\e699";
}

.icon-sc:before {
  content: "\e69a";
}

.icon-dbsx:before {
  content: "\e69b";
}

.icon-weizhi1:before {
  content: "\e69f";
}

.icon-png:before {
  content: "\e6a0";
}

.icon-jpg:before {
  content: "\e6a1";
}

.icon-Excel:before {
  content: "\e6a2";
}

.icon-ppt:before {
  content: "\e6a3";
}

.icon-word:before {
  content: "\e6a4";
}

.icon-zip:before {
  content: "\e6a5";
}

.icon-PDF:before {
  content: "\e6a6";
}

.icon-dituweizhi:before {
  content: "\e688";
}

.icon-tongji:before {
  content: "\e66d";
}

.icon-fangyuanzhuangtai:before {
  content: "\e66e";
}

.icon-yichangchuli:before {
  content: "\e66f";
}

.icon-works:before {
  content: "\e670";
}

.icon-fangyuanku:before {
  content: "\e671";
}

.icon-xiangmuguanli:before {
  content: "\e672";
}

.icon-xunchajilu:before {
  content: "\e673";
}

.icon-xuncharenwu:before {
  content: "\e674";
}

.icon-xunchayuanguanli:before {
  content: "\e675";
}

.icon-jichushuju:before {
  content: "\e676";
}

.icon-zijin:before {
  content: "\e677";
}

.icon-xunchaheshi:before {
  content: "\e678";
}

.icon-xunjianchoucha:before {
  content: "\e679";
}

.icon-hetong:before {
  content: "\e67a";
}

