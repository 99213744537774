//房屋状态色值 const color = ['#00C8A1', '#007AFF', '#678EEE', '#ACBFD0', '#889EAE', '#C0C0C0'];
@status-stock: #00c8a1; // 存量
@status-deploy: #007aff; // 待签约
@status-sign: #678eee; // 已签约过审
@status-sell: #acbfd0; // 已对接清册
@status-adjust: #889eae; // 已调剂
@status-back: #c0c0c0; // 已退订
//历史房源录入状态色值
@status-complete: #17e4e3; // 已确认
@status-wait: #007aff; // 待确认
@status-reject: #ff2d3b; // 已驳回
@status-draft: #666; // 待确认

//房源状态：4: '已签约过审',  5: '存量',  6: '待签约',  7: '已对接清册',  8: '已调剂',  0: '已退订'
//表格房源状态点样式
.table-dot-type {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  min-width: 80px;
  margin-right: 10px;

  .table-dot {
    width: 8px;
    height: 8px;
    margin-right: 5px;
    border-radius: 50%;
  }
}

// 存量
.table-dot-stock {
  background: @status-stock;
}

.table-dot-5 {
  background: @status-stock;
}

// 待签约
.table-dot-deploy {
  background: @status-deploy;
}

.table-dot-6 {
  background: @status-deploy;
}

// 已签约过审
.table-dot-sign {
  background: @status-sign;
}

.table-dot-4 {
  background: @status-sign;
}

// 已对接清册
.table-dot-sell {
  background: @status-sell;
}

.table-dot-7 {
  background: @status-sell;
}

// 已调剂
.table-dot-adjust {
  background: @status-adjust;
}

.table-dot-8 {
  background: @status-adjust;
}

// 已退订
.table-dot-back {
  background: @status-back;
}

.table-dot-0 {
  background: @status-back;
}

//是否分配部门   1: '已分配', 0: '未分配',
.distribute-status-1 {
  background: #007aff;
  color: #fff;
}

.distribute-status-0 {
  background: #999;
  color: #fff;
}

//任务处理状态   1-未处理 2-已处理 3-已确认
.task-status-1 {
  background: #e74826;
  color: #fff;
}

.task-status-2 {
  background: #3fc4c0;
  color: #fff;
}

.task-status-3 {
  background: #007aff;
  color: #fff;
}

// 表格房源状态tag样式
.table-status-tag {
  display: inline-block;
  padding: 0 7px;
  //height: 20px;
  //line-height: 20px;
  border-radius: 3px;
  font-size: 10px;
  font-weight: 500;
}

// 表格房源状态tag样式
.detail-status-tag {
  display: inline-block;
  margin-left: 10px;
  padding: 0 7px;
  //height: 20px;
  //line-height: 20px;
  border-radius: 3px;
  font-size: 10px;
  font-weight: 500;
}

//存量状态
.table-status-tag-5 {
  background: rgba(0, 200, 161, 0.05);
  border: 1px solid @status-stock;
  color: @status-stock;
}

//待签约状态
.table-status-tag-6 {
  background: rgba(0, 122, 255, 0.05);
  border: 1px solid @status-deploy;
  color: @status-deploy;
}

//已签约审核
.table-status-tag-4 {
  background: rgba(103, 142, 238, 0.05);
  border: 1px solid @status-sign;
  color: @status-sign;
}

//已对接清册状态
.table-status-tag-7 {
  background: rgba(172, 191, 208, 0.05);
  border: 1px solid @status-sell;
  color: @status-sell;
}

//已调剂状态
.table-status-tag-8 {
  background: rgba(136, 158, 174, 0.05);
  border: 1px solid @status-adjust;
  color: @status-adjust;
}

//已退订状态
.table-status-tag-0 {
  background: rgba(192, 192, 192, 0.05);
  border: 1px solid @status-back;
  color: @status-back;
}

//已签约审核
.table-status-tag-red {
  border: 1px solid #ff2d3b;
  color: #ff2d3b;
}

// 存量
.text-stock {
  color: @status-stock;
}

// 待签约
.text-deploy {
  color: @status-deploy;
}

// 已去化
.text-sell {
  color: @status-sell;
}

// 已调剂
.text-adjust {
  color: @status-adjust;
}

// 已退订
.text-back {
  color: @status-back;
}

//已确认状态
.table-complete-status-tag {
  background: rgba(173, 255, 213, 0.05);
  border: 1px solid @status-complete;
  color: @status-complete;
}

//计划运营部:1-未开始  2-进行中 3-已超期
.patrol-status-1 {
  //background: rgba(173, 255, 213, 0.05);
  border: 1px solid #666;
  color: #666;
}

.patrol-status-2 {
  //background: rgba(173, 255, 213, 0.05);
  border: 1px solid #007aff;
  color: #007aff;
}

.patrol-status-3 {
  //background: rgba(173, 255, 213, 0.05);
  border: 1px solid #ff2d3b;
  color: #ff2d3b;
}

// 已完成状态
.table-complete-status-border {
  //background: rgba(173, 255, 213, 0.05);
  border: 1px solid #333;
  color: #333;
}

//草稿状态
.text-draft-status-tag {
  background: rgba(173, 255, 213, 0.05);
  border: 1px solid @status-draft;
  color: @status-draft;
}

//待确认状态
.text-wait-status-tag {
  background: rgba(173, 255, 213, 0.05);
  border: 1px solid @status-wait;
  color: @status-wait;
}

//已驳回状态
.table-reject-status-tag {
  background: rgba(173, 255, 213, 0.05);
  border: 1px solid @status-reject;
  color: @status-reject;
}

// 已确认
.text-complete {
  color: @status-complete;
}

// 待确认
.text-wait {
  color: @status-wait;
}

// 已驳回
.text-reject {
  color: @status-reject;
}

//公司主体- 1: '城发投',  2: '城市更新',  3: '保障房',  4: '土地分中心' 5:地产集团
.symbol-company {
  display: inline-block;
  width: auto;
  height: 24px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  padding: 0 17px;
  line-height: 24px;
  margin-right: 7px;
}

//城发投
.symbol-company-1 {
  color: #5d69be;
  background: linear-gradient(315deg, #bbcfff 0%, #eff1f6 100%);
}

//城发更新
.symbol-company-2 {
  color: #215996;
  background: linear-gradient(315deg, #94d3ff 0%, #e8f6ff 100%);
}

//保障房
.symbol-company-3 {
  color: #543f93;
  background: linear-gradient(315deg, #ccbbff 0%, #f8f6fd 100%);
}

//土地分中心
.symbol-company-4 {
  color: #1d647e;
  background: linear-gradient(315deg, #c4f0fd 0%, #eef6fe 100%);
}

//地产集团
.symbol-company-5 {
  color: #215996;
  background: linear-gradient(315deg, #94d3ff 0%, #e8f6ff 100%);
}

//表格几个状态图标样式
//备案
.icon-table-isPreRecord {
  color: #65d3c0;
  font-size: 16px;
  margin-right: 4px;
}

//冻结
.icon-table-freezeStatus {
  color: #007aff;
  font-size: 16px;
  margin-right: 4px;
}

//锁定
.icon-table-lockStatus {
  color: #f7bd00;
  font-size: 16px;
  margin-right: 4px;
}

// 巡查异常
.icon-table-exceptionType {
  color: #ee686d;
  font-size: 16px;
  margin-right: 4px;
}

//详情标题几个状态图标样式
//备案
.icon-info-isPreRecord {
  color: #65d3c0;
  font-size: 18px;
  margin-right: 4px;
}

//冻结
.icon-info-freezeStatus {
  color: #007aff;
  font-size: 18px;
  margin-right: 4px;
}

//锁定
.icon-info-lockStatus {
  color: #f7bd00;
  font-size: 18px;
  margin-right: 4px;
}

// 巡查异常
.icon-info-exceptionType {
  color: #ee686d;
  font-size: 18px;
  margin-right: 4px;
}
